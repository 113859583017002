import Vue from "vue";

/*TITLE*/
document.title = "Residencial Don Cristóbal | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Don Cristóbal";
Vue.prototype.$subtitle = "";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Don Cristóbal";
Vue.prototype.$introSubtitle = "";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-2e-bottega-caliza-rgb--20230127090124.jpg";
Vue.prototype.$image_kitchen = "cocina-bottega-caliza-china-blanco-solo-white-nature-rgb--20230127090140.jpg";
Vue.prototype.$image_bath1 = "banyo-1-bottega-caliza-newport-beige-old-beige-rgb--20230127090110.jpg";
Vue.prototype.$image_bath2 = "banyo-2-bottega-caliza-newport-natural-old-natural-rgb--20230127090118.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-2e-bottega-caliza-rgb--20230127090124.jpg",
  },
  {
    src: "salon-1-bottega-caliza-rgb--20230127090130.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-bottega-caliza-china-blanco-solo-white-nature-rgb--20230127090140.jpg",
  },
  {
    src: "cocina-bottega-caliza-china-blanco-solo-warme-nature-rgb--20230127090147.jpg",
  },
  {
    src: "cocina-bottega-caliza-china-blanco-solo-dove-nature-rgb--20230127090156.jpg",
  },
  {
    src: "cocina-bottega-caliza-china-blanco-solo-black-nature-rgb--20230127090103.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1-bottega-caliza-newport-beige-old-beige-rgb--20230127090110.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2-bottega-caliza-newport-natural-old-natural-rgb--20230127090118.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/DON_CRISTOBAL/";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=torremolinos",
    text: "Torremolinos",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:952441103",
    text: "952441103",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@promocionespeyca.com",
    text: "info@promocionespeyca.com",
  },
];
